import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { red } from "../../constants/color_constants";
import { StyledText, Wrapper } from "./styles";

function ErrorText({ children }) {
  return (
    <Wrapper>
      <FontAwesomeIcon icon={faTriangleExclamation} color={red} size="lg" />
      <StyledText>{children}</StyledText>
    </Wrapper>
  );
}

export default ErrorText;
