// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: [process.env.REACT_APP_FIREBASE_API_KEY],
  authDomain: "os-kampanj.firebaseapp.com",
  projectId: "os-kampanj",
  storageBucket: "os-kampanj.appspot.com",
  messagingSenderId: "555941517656",
  appId: "1:555941517656:web:a965b02d8f7d9d1ddb2d2d",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default getFirestore();
