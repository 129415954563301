import { styled, css } from "styled-components";

import bgWeb1 from "./assets/images/competition_web_1x.jpg";
// import bgPhone1 from "./assets/images/competition_phone_1x.jpg";
// import bgPhone2 from "./assets/images/competition_phone_2x.jpg";
import {
  black,
  blue,
  darkBlue,
  focus,
  mustard,
  red,
  white,
} from "./constants/color_constants";

const Background = styled.div`
  /* flex: 1; */
  background: linear-gradient(to bottom, ${black}, 85%, ${darkBlue});
  /* width: 100vw; */
  /* overflow-y: hidden; */
  min-height: 100vh;
`;

const BackgroundImage = styled.div`
  position: absolute;
  background-image: linear-gradient(to bottom, transparent, 80%, ${black}),
    url(${bgWeb1});
  background-repeat: no-repeat;
  // background-size: calc(100vh * (1440 / 2443)) 100vh,
  //   calc(100vh * (1440 / 2443) 100vh);
  background-size: cover;
  background-position: top;
  width: 100%;
  min-height: 300%;
  left: 0;
  right: 0;
  z-index: 0;

  @media (max-width: 800px) {
    filter: blur(12px);
    -webkit-filter: blur(12px);
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const ContentContainer = styled.div`
  /* width: 100vh; */
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  > .flexspace {
    flex: 0 1 0%;
  }
`;

const StyledFooter = styled.footer`
  position: absolute;
  background: ${blue};
  height: 160px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledHeader = styled.header`
  background: transparent;
  height: 160px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled.button`
  cursor: pointer;
  color: ${white};
  background-color: ${black};
  gap: 10px;
  margin: 24px 0;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-style: italic;
  border: 0px solid transparent;
  font-weight: 700;

  ${({ type }) =>
    type === "white" &&
    css`
      background-color: ${white};
      color: ${black};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  @media (max-width: 800px) {
    width: 100%;
    padding: 18px 28px;
    font-size: 18px;
  }
`;

const TextButton = styled.button`
  background: transparent;
  border: none;
  text-decoration: underline;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding-top: 24px;
  cursor: pointer;
`;

const IconButton = styled.button`
  background: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  padding: 24px;
`;

const Heading = styled.h1`
  color: ${white};
  font-size: 58px;
  font-family: Montserrat;
  text-transform: uppercase;
`;

const TextSection = styled.div`
  top: 0;
  list-style: none;
  display: flex: 
  flex-direction: column;
  flex: 0 0 600px;
  text-align: center;
   @media (max-width: 800px) {
    flex: 0 0 80vw;
  }

`;

const TextContainer = styled.div`
  padding-bottom: 48px;
  span {
    color: ${white};
    font-size: 16px;
    line-height: 24px;
  }

  .bold {
    font-weight: bold;
  }
`;

const StyledFormContainer = styled.div`
  background: ${white};
  border: 6px solid ${black};
  border-radius: 24px;
  padding: 48px;
  margin: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .question {
    font-size: 24px;
    font-weight: bold;
  }
  @media (max-width: 500px) {
    padding: 12px;
  }
`;

const FormContainerCircle = styled.div`
  background: ${mustard};
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
`;

const StyledTextArea = styled.textarea`
  border: 1px solid ${black};
  padding: 12px 18px 12px 18px;
  font-size: 16px;
  margin-bottom: 12px;
  border-radius: 12px;
  font-family: Montserrat;

  &&:focus {
    border: 2px solid ${focus};
    outline: none;
  }

  ${({ error }) =>
    error === "true" &&
    css`
      border-color: ${red};
    `}
`;

const TextInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  > p {
    padding-left: 8px;
  }
`;

const StyledErrorText = styled.p`
  color: ${red};
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

const RecaptchaContainer = styled.div`
  padding-top: 24px;
`;

export {
  Wrapper,
  StyledButton,
  ContentContainer,
  Background,
  BackgroundImage,
  Heading,
  TextSection,
  StyledFormContainer,
  FormContainerCircle,
  StyledTextArea,
  TextInputWrapper,
  StyledFooter,
  StyledHeader,
  TextContainer,
  TextButton,
  IconButton,
  StyledErrorText,
  ErrorWrapper,
  RecaptchaContainer,
};
