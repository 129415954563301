import {
  StyledErrorText,
  FormContainerCircle,
  StyledFormContainer,
  StyledTextArea,
  TextButton,
  TextInputWrapper,
  ErrorWrapper,
  RecaptchaContainer,
} from "../styles";
import SelectInput from "./radio_input/radio_input";
import TextInput from "./text_input/text_input";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUser,
  faCoffee,
  faEnvelope,
  faKeyboard,
  faMobileScreen,
  faPhone,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { MODAL_TYPE_TERMS } from "../constants/app_constants";
import Button from "./button/button";
import ErrorText from "./error_text/error_text";
import { red } from "../constants/color_constants";

const FormContainer = ({
  data,
  onSubmit,
  handleUpdate,
  answerData,
  recaptcha,
  openModal,
  loading,
  completed,
  errorMsg,
  formErrors,
}) => {
  return (
    <StyledFormContainer>
      <FormContainerCircle>
        {data.index} / {data.total}
      </FormContainerCircle>
      {data.questionData.type === "select" && (
        <TextInputWrapper>
          <p className="question">{data.questionData.question}</p>
          <SelectInput
            alternatives={data.questionData.alts}
            name={data.questionData.fieldName}
            handleUpdate={handleUpdate}
            value={answerData[data.questionData.fieldName]}
          />
          {formErrors[data.questionData.fieldName] && (
            <ErrorText>{formErrors[data.questionData.fieldName]}</ErrorText>
          )}
        </TextInputWrapper>
      )}
      {data.questionData.type === "input" && (
        <>
          <p className="question">{data.questionData.question}</p>
          <TextInputWrapper>
            <StyledTextArea
              placeholder="Skriv här..."
              name="memoryText"
              onChange={handleUpdate}
              rows="4"
              error={formErrors["memoryText"] ? "true" : "false"}
            />
            {formErrors["memoryText"] && (
              <ErrorText>{formErrors["memoryText"]}</ErrorText>
            )}
          </TextInputWrapper>
          <p className="question">
            Grymt jobbat! Nu är det bara kontaktinfo kvar.
          </p>
          <TextInputWrapper>
            <TextInput
              onChange={handleUpdate}
              name="fullName"
              placeholder="Hela namnet"
              error={formErrors["fullName"]}
              icon={faCircleUser}
            />
            <TextInput
              onChange={handleUpdate}
              name="email"
              placeholder="Epost"
              error={formErrors["email"]}
              icon={faEnvelope}
            />
            <TextInput
              onChange={handleUpdate}
              name="phone"
              placeholder="Telefonnummer"
              error={formErrors["phone"]}
              icon={faMobileScreen}
            />
          </TextInputWrapper>
          {!recaptcha.current?.getValue() && (
            <RecaptchaContainer>
              <ReCAPTCHA
                ref={recaptcha}
                sitekey={process.env.REACT_APP_SITE_KEY}
              />
            </RecaptchaContainer>
          )}
          <Button
            onClick={() => (completed ? null : onSubmit())}
            type="submit"
            disabled={completed}
            loading={loading}
          >
            Skicka
          </Button>
          {/* {console.log("ENV", process.env.REACT_APP_SITE_KEY)} */}
          {/* <p>{}</p> */}
          {errorMsg && (
            <ErrorWrapper>
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                color={red}
                size="lg"
              />
              <StyledErrorText>{errorMsg}</StyledErrorText>
            </ErrorWrapper>
          )}
          <TextButton onClick={() => openModal(MODAL_TYPE_TERMS)}>
            Allmänna villkor
          </TextButton>
        </>
      )}
    </StyledFormContainer>
  );
};

export default FormContainer;
