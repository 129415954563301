import { styled, keyframes } from "styled-components";

const ModalBackground = styled.div`
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: ${({ visibility }) => visibility};
  opacity: ${({ visibility }) => (visibility ? 1 : 0)};
`;

const StyledModal = styled.div`
  background: #ffffff;
  border: 6px solid #000000;
  width: 500px;
  border-radius: 24px;
  padding: 48px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  > p {
    font-size: 20px;
    line-height: 24px;
  }
  .header {
    font-size: 24px;
    font-weight: bold;
  }
  @media (max-width: 800px) {
    padding: 24px;
    width: 80vw;
  }

  @media (max-width: 400px) {
    padding: 12px;
  }
`;

const ModalTopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ModalTopRowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .header {
    padding-left: 12px;
  }
`;

export { ModalBackground, StyledModal, ModalTopRow, ModalTopRowCenter };
