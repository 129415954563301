import { styled } from "styled-components";

const Seperator = styled.div`
  display: block;
  width: 2px;
  background-color: #ffffff;
  height: 80%;
  margin: 0px 48px;
  @media (max-width: 1000px) {
    height: 60%;
  }
  @media (max-width: 600px) {
    height: 50%;
    width: 1px;
    margin: 0 24px;
  }
`;

const StyledImg = styled.img`
  height: 100px;
  @media (max-width: 1000px) {
    height: 70px;
  }
  @media (max-width: 600px) {
    height: 50px;
  }
`;

export { Seperator, StyledImg };
