import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css, styled } from "styled-components";
import { black, blue, focus, red } from "../../constants/color_constants";

const StyledTextInput = styled.input`
  border: none;
  font-size: 16px;
  flex: 1;
  font-family: Montserrat;
  &&:focus {
    outline: none;
    box-shadow: none;
  }
`;

const TextInputContainer = styled.div`
  border: 1px solid ${black};
  border-radius: 12px;
  margin: 12px 0;
  display: flex;
  padding: 18px 24px;
  align-items: center;
  > input {
    padding-left: 12px;
  }
  &&:has(input:focus) {
    border: 2px solid ${focus};
    > svg {
      color: ${focus};
    }
  }

  ${({ error }) =>
    error === "true" &&
    css`
      border-color: ${red};
    `}
`;

export { StyledTextInput, TextInputContainer };
