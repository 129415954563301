import { styled, keyframes, css } from "styled-components";
import { black, lightBlue, red, white } from "../../constants/color_constants";

const popIn = keyframes`
from {
  opacity: 0;
  transform: translate(-50%, -50%) scale(1.5) ;
}
to {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1) ;
}
`;

const Label = styled.label`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0.6em 0;
  font-size: 24px;
  font-weight: bold;
  border: 1px solid ${black};
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
  &&:before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    border: 2px solid ${black};
    left: 16px;
    border-radius: 50%;
  }
`;

const Input = styled.input`
  height: 0;
  width: 0;
  display: none;
  opacity: 0;
  cursor: pointer;
  z-index: -1;
  &&:checked + ${Label} {
    background-color: ${lightBlue};
    color: ${white};
    border: 1px solid ${lightBlue};
  }
  &&:checked + ${Label}:before {
    background: ${lightBlue};
    border: 0.3em solid ${white};
    height: 12px;
    width: 12px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  + .error {
    color: ${red};
    align-self: start;
    padding: 12px 24px;
    font-size: 16px;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
   to {
    opacity: 1;
   }
`;

export { Wrapper, Input, Label };
