import { faCheckCircle, faCircleCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MODAL_TYPE_CONFIRM, MODAL_TYPE_TERMS } from "../../constants/app_constants";
import { IconButton } from "../../styles";
import { ModalBackground, ModalTopRow, ModalTopRowCenter, StyledModal } from "./styles";

function Modal({ type, closeModal, setCompleted }) {
  return (
    <ModalBackground
      visibility={type ? "visible" : "hidden"}
      onClick={() => {
        if (type === MODAL_TYPE_CONFIRM) setCompleted(true);
        closeModal();
      }}
    >
      <StyledModal>
        {type === MODAL_TYPE_CONFIRM && (
          <>
            <ModalTopRowCenter>
              <FontAwesomeIcon icon={faCircleCheck} color="#00A651" size="2x" />
              <p className="header">Skickat!</p>
            </ModalTopRowCenter>
            <p>Nu håller vi tummarna! </p>
          </>
        )}
        {type === MODAL_TYPE_TERMS && (
          <>
            <ModalTopRow>
              <p className="header">Terms & Conditions</p>
              <IconButton onClick={closeModal}>
                <FontAwesomeIcon icon={faXmark} size="2x" />
              </IconButton>
            </ModalTopRow>
            <div>
              <div>
                <p>Tävlingsvillkor</p>
              </div>
              <div>
                <p>1. Tävlingsarrangör</p>
              </div>
              <div>
                <p>Denna tävling arrangeras av Toyota och Bridgestone ("Arrangörerna") och genomförs av Dentsu på uppdrag av Arrangörerna. * Genom att delta i tävlingen godkänner du dessa tävlingsvillkor.</p>
              </div>
              <div>
                <p>2. Deltagande</p>
              </div>
              <div>
                <p>Tävlingen är öppen för dig som är minst 18 år gamla och bosatta i Sverige. Anställda hos Arrangörerna och deras familjemedlemmar får inte delta i tävlingen.</p>
              </div>
              <div>
                <p>3. Hur man deltar</p>
              </div>
              <div>
                <p>
                  För att delta i tävlingen måste du besöka www.
                  <a href="https://url.uk.m.mimecastprotect.com/s/VSseC32qwHVAMgZ4tgfxsOLQnr?domain=closerparis2024.se" target="_blank" rel="noreferrer noopener">
                    closerparis2024.se
                  </a>
                  och svara på frågorna, motivera varför just du ska få åka till Paralympiska Spelen i Paris och ange dina fullständiga kontaktuppgifter. Endast ett bidrag per person är tillåtet. Bidraget måste vara original och får inte innehålla material som kränker tredje parts rättigheter.
                </p>
              </div>
              <div>
                <p>Om tävlingen publiceras i sociala medier är denna inte på något sätt sponsrad, stödd eller administrerad av eller knuten till den valda sociala medie-kanalen. För att kunna delta i tävlingen måste du ha en öppen profil. Din sociala medie-profil måste också vara skapad i enlighet med respektive plattforms villkor. Exempelvis måste du ha en fullständig version av Instagram/Facebook.</p>
              </div>
              <div>
                <p>4. Tävlingsperiod</p>
              </div>
              <div>
                <p>Tävlingen börjar den 5 augusti och pågår som längst till 30 augusti 2024, vinnare kommer att väljas löpande.</p>
              </div>
              <div>
                <p>5. Priser</p>
                <p>Inlösen/ verkställandet av resan till Paris sker från 6-8 september, beroende på tillgänglighet av biljetter, hotell och flyg. Detta är upp till arrangören att bestämma och kan ej överklagas.</p>
              </div>
              <div>
                <p>Varje vinnare får ett pris som inkluderar:</p>
              </div>
              <div>
                <ul>
                  <li>
                    <p>Flygresa tur och retur från Stockholm till Paris för vinnaren och en medresenär.</p>
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    <p>Hotellövernattning i Paris för vinnaren och en medresenär.</p>
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    <p>Biljetter till Paralympiska Spelen under vistelsen för vinnaren och en medresenär.</p>
                  </li>
                </ul>
              </div>
              <div>
                <p>Priset inkluderar inte mat, resor inom Paris, reseförsäkring eller andra kostnader.</p>
              </div>
              <div>
                <p>6. Vinnare</p>
              </div>
              <div>
                <p>Fyra vinnaren kommer att utses av en jury bestående av representanter för Arrangörerna och juryns beslut kan inte överklagas. Vinnaren kommer att meddelas via e-post eller telefon inom två arbetsdagar efter tävlingens slut. Om en vinnare inte har bekräftat meddelandet om vinst inom 24 timmar från att det skickades förbehåller sig Arrangören rätten att välja en annan vinnare.</p>
              </div>
              <div>
                <p>Eventuell vinstskatt betalas av vinnarna</p>
              </div>
              <div>
                <p>7. Personuppgifter</p>
              </div>
            </div>
            <div>
              <p>Genom att delta i tävlingen samtycker du till att Arrangörerna och Dentsu behandlar dina personuppgifter för att genomföra tävlingen. Dina personuppgifter kommer att behandlas i enlighet med Arrangörernas och Dentsus respektive integritetspolicies.</p>
            </div>
            <div>
              <p>8. Övrigt</p>
            </div>
            <div>
              <p>Dentsu ansvarar inte för tekniska problem som kan påverka deltagandet i tävlingen eller utdelningen av priser. Arrangörerna förbehåller sig rätten att ändra tävlingsreglerna eller avbryta tävlingen om det skulle behövas på grund av omständigheter som ligger utanför Arrangörernas kontroll.</p>
            </div>
            <div>
              <p>Samtliga priser är personliga och kan inte överlåtas till annan. Det är inte möjligt att byta ut vinsten mot kontanter.</p>
            </div>
            <div>
              <p>Du åtar dig att hålla Arrangörerna och Dentsu skadeslösa från alla anspråk (inklusive anspråk från tredje part) i samband med användningen av ditt tävlingsbidrag som beskrivs ovan.</p>
            </div>
            <div>
              <p>9. Svensk lag</p>
            </div>
            <div>
              <p>Tävlingen regleras av svensk lag. Eventuella tvister som uppstår i samband med tävlingen ska avgöras av svensk domstol. </p>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div>
              <p>
                * Bridgestone, Ulls väg 29A, Uppsala, Sverige <br />
                Toyota, MADENVÄGEN 7, Sundbyberg, Sverige <br />
                Dentsu Åsögatan 108, Stockholm, Sverige
              </p>
            </div>
            <div></div>
            <div></div>
          </>
        )}
      </StyledModal>
    </ModalBackground>
  );
}

export default Modal;
