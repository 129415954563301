import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { black, red } from "../../constants/color_constants";
import ErrorText from "../error_text/error_text";
import { StyledTextInput, TextInputContainer } from "./styles";

function TextInput({ placeholder, id, onChange, name, error, icon }) {
  return (
    <>
      <TextInputContainer error={error ? "true" : "false"}>
        <FontAwesomeIcon
          icon={icon || faCoffee}
          size="lg"
          color={error ? red : black}
        />
        <StyledTextInput
          placeholder={placeholder}
          onChange={onChange}
          name={name}
        />
      </TextInputContainer>
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
}

export default TextInput;
