import "./App.css";
import { BackgroundImage, Heading, TextSection, Background, ContentContainer, StyledFooter, StyledHeader, Wrapper, TextContainer } from "./styles.js";
import { useRef, useState, useEffect } from "react";

import FormContainer from "./components/form_container";
import Icons from "./components/icons/icons";

import db from "./firebase";
import { collection, onSnapshot } from "firebase/firestore";
import Modal from "./components/modal/modal";
import { postParticipant, updateStatistics } from "./helpers/firebase_helpers";
import { DB_COLLECTION_STATISTICS, MODAL_TYPE_CONFIRM, MODAL_TYPE_TERMS } from "./constants/app_constants";
import Button from "./components/button/button";

const formContainers = [
  {
    question: "I vilken stad finns Eiffeltornet?",
    type: "select",
    fieldName: "questionOne",
    alts: [
      {
        label: "Berlin",
        value: "Berlin",
        isCorrect: false,
      },
      {
        label: "Madrid",
        value: "Madrid",
        isCorrect: false,
      },
      {
        label: "Paris",
        value: "Paris",
        isCorrect: true,
      },
    ],
  },
  {
    question: "Vad heter floden som rinner genom Paris?",
    type: "select",
    fieldName: "questionTwo",
    alts: [
      {
        label: "Themsen",
        value: "Themsen",
        isCorrect: false,
      },
      {
        label: "Seine",
        value: "Seine",
        isCorrect: true,
      },
      {
        label: "Göta Kanal",
        value: "Göta Kanal",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Vilken är din bästa sportupplevelse?",
    type: "input",
  },
];

const initialState = {
  questionOne: null,
  questionTwo: null,
  memoryText: null,
  fullName: null,
  email: null,
  phone: null,
};

function App() {
  useEffect(() => {
    // console.log("USEEFFECT");
    onSnapshot(collection(db, DB_COLLECTION_STATISTICS), (snap) => {
      const newStatistics = snap.docs[0].data();
      setStatistics(newStatistics);
      // console.log("NEW STATS", newStatistics);
    });
  }, []);

  const [answerData, setAnswerData] = useState(initialState);
  const [modalType, setModalType] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [formErrors, setFormErrors] = useState(initialState);

  const handleUpdate = ({ target }) => {
    const { name, value } = target;
    setAnswerData({
      ...answerData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: null,
    });
    setErrorMsg(null);
  };

  const validateRadioQuestions = () => {
    let correct = true;
    formContainers.forEach((q) => {
      if (q.type === "select") {
        const correctAlt = q.alts.find((alt) => alt.isCorrect);
        const { fieldName } = q;
        const userAnswer = answerData[fieldName];
        if (userAnswer !== correctAlt.value) correct = false;
      }
    });
    return correct;
  };

  const validateInputs = () => {
    const defaultRequiredMsg = "Får ej lämnas tomt";
    let newFormErrors = { ...formErrors };
    let hasErrors = false;
    const fields = ["questionOne", "questionTwo", "memoryText", "fullName", "email", "phone"];

    fields.forEach((field) => {
      if (!answerData[field] || answerData[field] === "") {
        newFormErrors = {
          ...newFormErrors,
          [field]: defaultRequiredMsg,
        };
        hasErrors = true;
      }
    });

    setFormErrors(newFormErrors);
    // console.log("returning hasErrors", hasErrors);
    return hasErrors;
  };

  async function onSubmit(e) {
    e?.preventDefault();
    setLoading(true);
    const hasErrors = validateInputs();
    // console.log("submit hasErrors", hasErrors);
    if (hasErrors) {
      setErrorMsg("Uppgifter saknas");
      setLoading(false);
      return;
    }
    try {
      const userAnsweredCorrectly = validateRadioQuestions();
      const captchaValue = recaptcha.current.getValue();

      if (!captchaValue) {
        setErrorMsg("Verifiering saknas");
        setLoading(false);
        return;
      }
      if (userAnsweredCorrectly) await postParticipant(answerData);
      updateStatistics({
        userAnsweredCorrectly,
        currentStatistics: statistics,
      });
    } catch (error) {
      // console.log("ERROR SUBMIT", { error });
      setErrorMsg(error?.message || "Något gick fel");
      setLoading(false);
    }
    openModal(MODAL_TYPE_CONFIRM);
    setLoading(false);
  }

  const formStartRef = useRef(null);
  const recaptcha = useRef();

  const openModal = (type) => setModalType(type);

  return (
    <Background>
      <BackgroundImage />
      <Modal type={modalType} closeModal={() => setModalType(null)} setCompleted={setCompleted} />
      <Wrapper>
        <StyledHeader>
          <Icons />
        </StyledHeader>
        <ContentContainer>
          <div className="flexspace" />
          <TextSection>
            {/* <Button type="white" onClick={() => openModal(MODAL_TYPE_TERMS)}>
              Test
              
            </Button> */}
            <Heading>GET CLOSER TO PARIS 2024</Heading>
            <TextContainer>
              <span className="bold">Tävla om biljetter till Paralympiska Spelen 2024 </span>
              <span>– inklusive flyg (från Stockholm) och hotell! Svara på frågorna nedan för att vara med och tävla om en alldeles unik sportupplevelse. Aktuella datum för biljetter, flyg och hotell är 6–8 september.</span>
            </TextContainer>
            <>
              <Button
                type="white"
                onClick={() => {
                  window.scrollTo({
                    top: formStartRef.current.offsetTop,
                    behavior: "smooth",
                  });
                }}
              >
                Tävla nu
              </Button>

              <form onSubmit={(e) => e.preventDefault()} noValidate ref={formStartRef}>
                {formContainers.map((cont, i) => (
                  <FormContainer
                    key={"form-container-" + i}
                    data={{
                      index: i + 1,
                      total: formContainers.length,
                      title: `title ${i}`,
                      questionData: cont,
                    }}
                    handleUpdate={handleUpdate}
                    answerData={answerData}
                    onSubmit={onSubmit}
                    recaptcha={recaptcha}
                    openModal={openModal}
                    loading={loading}
                    completed={completed}
                    errorMsg={errorMsg}
                    formErrors={formErrors}
                  />
                ))}
              </form>
            </>
          </TextSection>
          <div className="flexspace" />
        </ContentContainer>
        <StyledFooter>
          <Icons />
        </StyledFooter>
      </Wrapper>
    </Background>
  );
}

export default App;
