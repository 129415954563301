import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledButton } from "../../styles";

function Button({ disabled, type, onClick, loading, children }) {
  return (
    <StyledButton onClick={onClick} type={type} disabled={disabled || loading}>
      {loading ? (
        <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
      ) : (
        children
      )}
    </StyledButton>
  );
}

export default Button;
