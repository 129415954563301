import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import {
  DB_COLLECTION_PARTICIPANT,
  DB_COLLECTION_STATISTICS,
  DB_DOCUMENT_STATISTICS_DATA,
} from "../constants/app_constants";
import db from "../firebase";

async function checkIfParticipantExists({ email }) {
  const q = query(
    collection(db, DB_COLLECTION_PARTICIPANT),
    where("email", "==", email)
  );
  try {
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) return true;
    return false;
  } catch (error) {
    console.log("ERROR CHECKING PARTICIPANT", error);
  }
}

async function postParticipant(payload) {
  try {
    const participantExists = await checkIfParticipantExists({
      email: payload.email,
    });
    if (participantExists) {
      throw new Error("Epost-adressen finns redan registrerad");
    }
    const docRef = await addDoc(
      collection(db, DB_COLLECTION_PARTICIPANT),
      payload
    );
    // console.log("SUCCESS, docId", docRef.id);
  } catch (error) {
    console.log("ERROR POSTING PARTICIPANT", error);
    throw new Error(error);
  }
}

async function updateStatistics({
  userAnsweredCorrectly = true,
  currentStatistics,
}) {
  const { numOfCorrectSubmits, numOfSubmits } = currentStatistics;
  const payload = {
    numOfCorrectSubmits: userAnsweredCorrectly
      ? numOfCorrectSubmits + 1
      : numOfCorrectSubmits,
    numOfSubmits: numOfSubmits + 1,
  };

  const docRef = await setDoc(
    doc(db, DB_COLLECTION_STATISTICS, DB_DOCUMENT_STATISTICS_DATA),
    payload
  );

  console.log("Successfully update doc", docRef?.id);
}

export { postParticipant, updateStatistics, checkIfParticipantExists };
