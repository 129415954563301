const MODAL_TYPE_TERMS = "MODAL_TYPE_TERMS";
const MODAL_TYPE_CONFIRM = "MODAL_TYPE_CONFIRM";

const DB_COLLECTION_PARTICIPANT = "Participant";
const DB_COLLECTION_STATISTICS = "Statistics";
const DB_DOCUMENT_STATISTICS_DATA = "data";

export {
  MODAL_TYPE_CONFIRM,
  MODAL_TYPE_TERMS,
  DB_COLLECTION_PARTICIPANT,
  DB_COLLECTION_STATISTICS,
  DB_DOCUMENT_STATISTICS_DATA,
};
