import { Component, useState } from "react";
// import * as Icons from "react-icons/fa";
import { Input, Label, Wrapper } from "./styles";

const SelectInput = ({ alternatives, name, handleUpdate, value }) => {
  return (
    <Wrapper>
      {alternatives.map((alt) => (
        <RadioButton
          label={alt.label}
          value={alt.value}
          key={`radio-alt-${alt.value}`}
          name={name}
          id={"radio-value-" + alt.value}
          onChange={handleUpdate}
          checked={value === alt.value}
        />
      ))}
    </Wrapper>
  );
};

function RadioButton({ value, onChange, name, id, label, checked }) {
  return (
    <>
      <Input
        id={id}
        type="radio"
        role="radio"
        name={name}
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        checked={checked}
      />
      <Label htmlFor={id}>{label}</Label>
    </>
  );
}

export default SelectInput;
