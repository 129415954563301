import { styled } from "styled-components";
import { red } from "../../constants/color_constants";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-bottom: 8px;
  width: 100%;
`;

const StyledText = styled.span`
  color: ${red};
  font-size: 16px;
  padding-left: 8px;
`;

export { Wrapper, StyledText };
